<template>
  <div class="main-layout__content">
    <div class="content">
      <h1 class="page-title">Tournament</h1>
      <div class="container text-center">
        <!-- <iframe
          src="https://battlefy.com/embeds/join/5f718e3d83c8b203bad73d5f"
          title="POG Championship Cup  1 (Classic)"
          width="186"
          height="60"
          scrolling="no"
          frameborder="0"
        ></iframe> -->
        <div class="row">
          <div class="col-xl-12">
            <div class="my-stats">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="tb-head">Tournament</td>
                    <td class="tb-head">Date</td>
                    <td class="tb-head">Results</td>
                  </tr>
                  <tr>
                    <td>POG Championship Cup 2</td>
                    <td>November 1 2020</td>
                    <td>
                      <b-button
                        href="https://battlefy.com/dotacinema/pog-championship-cup-2-4v4-chaos/5f95475244bb4b3ea82eda8d/info"
                        target="_blank"
                        variant="primary"
                        >JOIN</b-button
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>POG Championship Cup 1</td>
                    <td>October 3rd 2020</td>
                    <td>
                      <b-button
                        href="https://battlefy.com/dotacinema/pog-championship-cup-1-classic/5f718e3d83c8b203bad73d5f/stage/5f718e85cc3d233e4492d8ae/bracket/"
                        target="_blank"
                        variant="primary"
                        >View Results</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
export default {};
</script>

<style>
</style>